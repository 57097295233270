html {
  font-size: 16px;
}

body {
  background: map-get($light, linen-light);
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;

  .divisor-line-container {
    padding: 0 25%;
    .divisor-line {
      border-top: 1.5px solid rgb(0, 0, 0, 0.3);
    }
  }

  &.dark {
    color: map-get($dark, mystic);
    background: map-get($dark, pickled-bluewood);

    .header {
      background: transparentize(map-get($dark, blue-bayoux-dark), 0.2);

      nav li .header__link {
        &:hover {
          color: rgb(164, 189, 212);
        }
      }
    }

    .divisor-line-container {
      .divisor-line {
        border-top: 1.5px solid rgba(255, 255, 255, 0.404);
      }
    }

    .work-cascade {
      a {
        border-color: map-get($dark, mystic);

        &:hover {
          background-color: #b7b8ff23;
        }
      }
    }

    .footer {
      background: map-get($dark, blue-bayoux-dark);
      color: map-get($dark, mystic);

      li a {
        &:hover {
          color: #1f1f1f;
        }
      }
    }
  }
}
