.footer {
  background: map-get($light, blueBayoux);
  font-family: $comfortaa-cursive;
  width: 100%;
  position: absolute;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icons-ul {
    font-size: 1.2rem;
    list-style: none;
    padding: 5rem 0 0 0;

    li {
      padding: 0 0 0.5rem 0;

      a {
        text-decoration: none;
        transition: color 0.2s;

        &:hover {
          color: map-get($dark, mystic);
          transition: color 0.15s;
        }

        .icon {
          padding-right: 1rem;
        }

        .label {
          padding: 0.2rem;
        }
      }
    }
  }

  .sign {
    padding: 2rem 0 1rem 0;
    margin: 0;
    list-style: none;

    li {
      font-size: 1.5rem;
    }
  }
}
