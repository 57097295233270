.header {
  background: transparentize(map-get($light, blueBayoux), 0.2);
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 4rem;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;

  &__h1 {
    cursor: default;
    position: fixed;
    padding-left: 1rem;
    transition: color 0.3s;
  }

  nav {
    right: 1rem;
    position: absolute;

    li {
      padding: 0rem 0.7rem;
      display: inline-block;

      .header__link {
        font-size: 1.02rem;
        text-decoration: none;
        padding: 0 0.6rem 0 0.6rem;
        transition: color 0.15s;

        &:hover {
          color: map-get($dark, mystic-darker);
        }
      }
    }
  }
}
