.works {
  .content {
    .section-gallery {
      .work-cards-container {
        position: relative;
        height: 100%;
        width: 100%;

        &:hover > .layer {
          opacity: 1;
          visibility: visible;
        }

        &:hover .img-layer img {
          filter: blur(4px);
        }

        .img-layer {
          margin: 0.2rem;
          height: 100%;
          width: 100%;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .layer {
          font-family: $comfortaa-cursive;
          margin: 0.2rem;
          position: absolute;
          top: 0;
          opacity: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          visibility: hidden;
          justify-content: center;
          align-items: center;
          text-align: center;
          transition: 0.4s;
          color: #ffffff;

          .text-container {
            height: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 3rem;

            h3 {
              text-align: center;
              font-size: 1.3rem;
              font-weight: bold;
            }

            p {
              padding-top: 1rem;
              text-align: center;
              font-size: 0.9rem;
            }
          }

          .buttons-container {
            div {
              a {
                color: white;
                border: 1px solid white;
                transition: all 0.25s ease-in;

                &:hover {
                  background-color: rgba($color: #ffffff, $alpha: 0.18);
                }
              }
            }
          }

          .techs {
            height: 25%;
            padding: 0;
            margin: 0;

            &__ul {
              position: absolute;
              bottom: 0;
              right: 0;
              // margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;

              //Tech img
              .tech-li {
                .tech-name {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
