.language-selector {
  cursor: pointer;
  border-radius: 10px;
  font-size: 0.8rem;
  padding: 0.3rem;
  color: black;

  option {
    font-size: 0.9rem;
    color: black;
  }
}

.switch-mobile-language {
  display: none;
  background: rgba($color: #ffffff, $alpha: 0.3);
  border-radius: 50%;
  border: 0.13rem solid rgba($color: #ffffff, $alpha: 0.5);
  padding: 0.25rem;
  cursor: pointer;
  transition: 0.5s;
  margin: 0 0 0 0.5rem;

  &:hover {
    background: rgba($color: #ffffff, $alpha: 0.5);
  }

  &.es {
    .en-svg {
      display: none;
    }
  }

  &.en {
    .es-svg {
      display: none;
    }
  }

  svg {
    opacity: 0.8;
    transform: scale(60%);
  }
}

body {
  &.dark {
    .language-selector {
      background-color: transparentize(map-get($dark, pickled-bluewood), 0.2);
      color: map-get($dark, mystic);

      option {
        color: map-get($dark, mystic);
        background: map-get($dark, pickled-bluewood);
      }
    }
  }
}
