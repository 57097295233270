@media (max-width: 576px) {
  html {
    font-size: 16px;
  }

  body {
    //Header
    .header {
      height: 2rem;
      height: 3rem;
      display: flex;
      justify-content: center;
    }

    //Intro
    .intro {
      .content {
        .profile-picture {
          .image-container {
            img {
              height: 21rem;
              width: 21rem;
            }
          }
        }

        header h2 {
          margin-top: 1.5rem;
          font-size: 2.5rem;
        }

        p {
          margin: 1rem;
          font-size: 1.2rem;
        }

        footer {
          padding-top: 1rem;

          li {
            .switch-mode-mobile {
              width: 5.6rem;
              height: 5.6rem;
            }
          }
        }

        .switch-mobile-language {
          border-radius: 50%;
          padding: 0.2rem;
        }
      }
    }

    //Works grid
    .works {
      .content {
        .work-tittle {
          h2 {
            font-size: 1.5rem;
          }

          p {
            font-size: 1.2rem;
          }
        }

        .section-gallery {
          .work-cards-container {
            display: flex;
            align-items: center;
            justify-content: center;

            .img-layer {
              margin: 0;
            }

            .layer {
              margin: 0;

              .text-container {
                padding: 0.2rem 1rem;

                h3 {
                  padding: 0.9rem 0 0 0;
                  margin: 0;
                  font-size: 1.2rem;
                }

                p {
                  padding: 0.2rem 0;
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }

    //About me
    .about-me {
      width: 100%;
      padding: 0;

      &__container {
        &:hover {
          .about-me__title {
            font-size: 1.5rem;
          }

          .about-me__description {
            font-size: 1.1rem;
            line-height: 1.5rem;
          }
        }

        .about-me__title {
          font-size: 1.5rem;
        }

        .about-me__description {
          font-size: 1.1rem;
          line-height: 1.5rem;
        }
      }
    }

    //Skills
    .skills {
      overflow-y: hidden;
      height: 100%;
      padding: 3rem 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .tech-container {
        margin: 0 0 0 0;
        width: 100%;
        height: 100%;
        display: flex;

        ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding: 0;

          .tech-li {
            .tech-img-container {
              width: 4rem;
            }

            .tech-name {
              font-size: 0.8rem;
              opacity: 1;
            }
          }
        }
      }

      .skills-description-aside {
        order: -1;
        position: static;
        margin: 0;
        height: 100%;
        width: 100%;

        &:hover {
          transform: scale(1);
          width: 100%;

          .skills-tittle-header {
            transition: font-size 0.5s;
            font-size: 1.5rem;
          }

          .skills-description {
            font-size: 1.1rem;
            line-height: 1.5rem;
          }
        }

        .skills-tittle-header {
          text-align: center;
          transition: font-size 0.5s;
          font-size: 1.5rem;
        }

        .skills-description {
          padding: 0rem 1rem;
          text-align: center;
          transition: font-size 0.5s;
          font-size: 1.1rem;
          line-height: 1.5rem;
        }
      }
    }

    //Footer
    .footer {
      .icons-ul {
        margin-left: -2rem;
      }

      .menu {
        margin-left: -2rem;
      }
    }
  }
}

@media (max-width: 321px) {
  html {
    font-size: 15px;
  }

  body {
    //Header
    .header {
      height: 2rem;
    }

    //Intro
    .intro {
      padding: 0 0 1rem 0;
      .content {
        .profile-picture {
          .image-container {
            img {
              height: 19rem;
              width: 19rem;
            }
          }
        }

        header {
          h2 {
            margin-top: 0.3rem;
            font-size: 1.8rem;
          }
        }

        p {
          padding: 1rem;
          margin: 0.3rem;
          font-size: 1.1rem;
        }

        footer {
          padding-top: 0.5rem;

          li {
            .switch-mode-mobile {
              width: 5.5rem;
              height: 5.5rem;
            }
          }
        }

        .switch-mobile-language {
          border-radius: 50%;
          padding: 0rem;

          svg {
            transform: scale(50%);
          }
        }
      }
    }

    //Work grid
    .works {
      .content {
        .section-gallery {
          .work-cards-container {
            .layer {
              .text-container {
                h3 {
                  font-size: 1.1rem;
                }

                p {
                  font-size: 0.7rem;
                }
              }

              .techs {
                &__ul {
                  //Tech img
                  .tech-li {
                    margin: 0 0.2rem 0 0;

                    .tech-img-container {
                      padding: 0.2rem 0.1rem;
                      height: 1.8rem;
                      width: 1.8rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    //About me
    .about-me {
      &__container {
        &:hover {
          .about-me__title {
            font-size: 1.3rem;
          }

          .about-me__description {
            font-size: 1.05rem;
          }
        }

        .about-me__title {
          font-size: 1.3rem;
        }

        .about-me__description {
          font-size: 1.05rem;
        }
      }
    }

    //Skills
    .skills {
      .tech-container {
        ul {
          .tech-li {
            padding: 1rem;

            .tech-img-container {
              height: 3rem;
              width: 3rem;
            }
          }
        }
      }

      .skills-description-aside {
        &:hover {
          .skills-tittle-header {
            font-size: 1.3rem;
          }

          .skills-description {
            font-size: 1.05rem;
          }
        }

        .skills-tittle-header {
          font-size: 1.3rem;
        }

        .skills-description {
          font-size: 1.05rem;
        }
      }
    }
  }
}
