@media (max-height: 850px) {
  html {
    font-size: 15px;
  }

  body {
    //Skills
    .skills {
      .tech-container {
        grid-template-columns: repeat(3, 22%);
      }
    }
  }
}

@media (max-height: 790px) {
  html {
    font-size: 14px;
  }

  body {
    //Skills
    .skills {
      .tech-container {
        grid-template-columns: repeat(3, 27%);
      }
    }
  }
}

@media (max-height: 620px) {
  html {
    font-size: 13px;
  }

  body {
    //Intro
    .intro {
      height: 100%;

      .content {
        .profile-picture {
          .image-container {
            margin: 4rem 0 0 0;
          }
        }

        footer {
          li {
            .switch-mode-mobile {
              width: 6.7rem;
              height: 6.7rem;
            }
          }
        }
      }
    }

    //Skills
    .skills {
      .tech-container {
        grid-template-columns: repeat(3, 27%);

        ul {
          .tech-li {
            padding: 1rem;
          }
        }
      }
    }
  }
}
