.works {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  .content {
    .work-tittle {
      text-align: center;

      h2 {
        font-size: 1.7rem;
      }

      p {
        font-size: 1.4rem;
      }
    }

    .section-gallery {
      width: 100%;
    }
  }
}
