.intro {
  font-family: $comfortaa-cursive;
  width: 100%;
  height: 100%;
  padding: 4rem 0 1rem 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../images/backgrounds/10.jpg");
  background-size: cover;
  background-attachment: fixed;

  .content {
    color: map-get($dark, mystic);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    header h2 {
      margin-top: 1rem;
      backdrop-filter: blur(2px);
      margin-bottom: 0;
      text-shadow: 0.1rem 0.1rem 0.2rem rgba(#322464, 1);
      text-align: center;
      font-size: 3.5rem;
    }

    p {
      backdrop-filter: blur(2px);
      text-shadow: 0.1rem 0.1rem 0.2rem rgba(#322464, 1);
      font-weight: bolder;
      font-size: 1.5rem;
      margin: 1rem;
      text-align: center;
    }

    .social-media-container {
      display: flex;
      align-items: center;

      .intro-icon {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 0.6rem;
        transition: visibility 0.2s;

        svg {
          height: 3rem;
          width: 3rem;
          cursor: pointer;
        }

        span {
          visibility: hidden;
          opacity: 0;
          font-size: 0.7rem;
          transition: opacity 0.3s;
        }

        &:hover {
          transform: scale(1.05);

          span {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    footer {
      padding-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;

      .down-section-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        border: 0.13rem solid rgba($color: #ffffff, $alpha: 0.5);

        .icon {
          color: rgba($color: #ffffff, $alpha: 0.3);
          height: 5rem;
          width: 5rem;
          transition: 0.3s;

          &:hover {
            color: rgba($color: #ffffff, $alpha: 0.5);
          }
        }
      }
    }
  }
}
