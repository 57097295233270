.skills {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: hidden;

  .tech-container {
    width: 72%;
    display: grid;
    grid-template-columns: repeat(3, 20%);
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.5s;

    ul {
      list-style: none;
    }
  }

  .skills-description-aside {
    position: absolute;
    right: 0;
    margin-right: -30%;
    width: 28%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: margin 0.3s, width 0.3s, transform 0.3s;
    overflow: hidden;

    &:hover {
      transform: scale(1.1);
      transition: transform 0.3s;

      .skills-tittle-header {
        transition: font-size 0.15s;
        font-size: 1.8rem;
      }

      .skills-description {
        transition: font-size 0.15s;
        font-size: 1.3rem;
      }
    }

    &.active {
      margin-right: 2rem;
    }

    .skills-tittle-header {
      transition: font-size 0.15s;
      font-size: 1.5rem;
    }

    .skills-description {
      transition: font-size 0.15s;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }
}
