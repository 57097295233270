@media (max-width: 1400px) {
  body {
    //Header
    .header {
      height: 3.5rem;

      nav li .header__link {
        font-size: 0.9rem;
      }
    }

    //Intro
    .intro {
      .content {
        .profile-picture {
          .image-container {
            margin-top: 2rem;
          }
        }
      }
    }

    //Works grid
    .works {
      .content {
        .section-gallery {
          //Work cell
          .work-container {
            .layer {
              .text-container {
                h3 {
                  font-size: 1.2rem;
                }

                p {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }

    //About me
    .about-me {
      &__container {
        margin-left: -42%;
        width: 100%;

        &:hover {
          padding: 25% 32%;

          .about-me__title {
            font-size: 1.7rem;
          }

          .about-me__description {
            width: 35rem;
            font-size: 1.2rem;
            line-height: 1.8rem;
          }
        }

        .about-me__title {
          width: 35rem;
          font-size: 1.4rem;
        }

        .about-me__description {
          width: 35rem;
          font-size: 1.1rem;
          line-height: 1.7rem;
        }
      }
    }

    //Skills
    .skills {
      .tech-container {
        width: 68%;
        margin-left: -1rem;
      }

      .skills-description-aside {
        width: 29%;
        margin-left: -1rem;

        &:hover {
          .skills-tittle-header {
            font-size: 1.7rem;
          }

          .skills-description {
            font-size: 1.2rem;
          }
        }

        .skills-tittle-header {
          font-size: 1.4rem;
        }

        .skills-description {
          font-size: 1.1rem;
        }
      }
    }
  }
}
