@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Josefin+Sans:wght@100&family=Nunito&family=Poppins:wght@100&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@100&family=Rubik:wght@300&family=Work+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Lora&family=Roboto+Mono&display=swap");
html {
  font-size: 16px;
}

body {
  background: #fcf8ec;
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
}
body .divisor-line-container {
  padding: 0 25%;
}
body .divisor-line-container .divisor-line {
  border-top: 1.5px solid rgba(0, 0, 0, 0.3);
}
body.dark {
  color: #dde6ed;
  background: #27374d;
}
body.dark .header {
  background: rgba(82, 109, 130, 0.8);
}
body.dark .header nav li .header__link:hover {
  color: rgb(164, 189, 212);
}
body.dark .divisor-line-container .divisor-line {
  border-top: 1.5px solid rgba(255, 255, 255, 0.404);
}
body.dark .work-cascade a {
  border-color: #dde6ed;
}
body.dark .work-cascade a:hover {
  background-color: rgba(183, 184, 255, 0.137254902);
}
body.dark .footer {
  background: #526d82;
  color: #dde6ed;
}
body.dark .footer li a:hover {
  color: #1f1f1f;
}

.switch-mode {
  background-color: #343d5b;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 0.11rem;
  align-items: center;
  justify-content: center;
}
.switch-mode::after {
  content: "";
  border-radius: 20px;
  width: 2.1rem;
  height: 2.1rem;
  background: white;
  display: block;
  position: absolute;
  right: unset;
  left: 0;
}
.switch-mode.active {
  background: rgb(161, 161, 161);
  color: black;
}
.switch-mode.active span {
  color: black;
}
.switch-mode.active::after {
  right: 0;
  left: unset;
}
.switch-mode span {
  width: 1.85rem;
  height: 1.85rem;
  line-height: 1.85rem;
  display: block;
  color: white;
  margin: 0px 0px 0px 5px;
}

.switch-mode-mobile {
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: 0.13rem solid rgba(255, 255, 255, 0.5);
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
}
.switch-mode-mobile:hover {
  background: rgba(255, 255, 255, 0.5);
  transition: 0.5s;
}
.switch-mode-mobile.active {
  color: #ffffff;
}
.switch-mode-mobile span {
  font-size: 3rem;
}

.works .content .section-gallery .work-cards-container {
  position: relative;
  height: 100%;
  width: 100%;
}
.works .content .section-gallery .work-cards-container:hover > .layer {
  opacity: 1;
  visibility: visible;
}
.works .content .section-gallery .work-cards-container:hover .img-layer img {
  filter: blur(4px);
}
.works .content .section-gallery .work-cards-container .img-layer {
  margin: 0.2rem;
  height: 100%;
  width: 100%;
}
.works .content .section-gallery .work-cards-container .img-layer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.works .content .section-gallery .work-cards-container .layer {
  font-family: "Comfortaa", cursive;
  margin: 0.2rem;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.4s;
  color: #ffffff;
}
.works .content .section-gallery .work-cards-container .layer .text-container {
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
}
.works .content .section-gallery .work-cards-container .layer .text-container h3 {
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
}
.works .content .section-gallery .work-cards-container .layer .text-container p {
  padding-top: 1rem;
  text-align: center;
  font-size: 0.9rem;
}
.works .content .section-gallery .work-cards-container .layer .buttons-container div a {
  color: white;
  border: 1px solid white;
  transition: all 0.25s ease-in;
}
.works .content .section-gallery .work-cards-container .layer .buttons-container div a:hover {
  background-color: rgba(255, 255, 255, 0.18);
}
.works .content .section-gallery .work-cards-container .layer .techs {
  height: 25%;
  padding: 0;
  margin: 0;
}
.works .content .section-gallery .work-cards-container .layer .techs__ul {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.works .content .section-gallery .work-cards-container .layer .techs__ul .tech-li .tech-name {
  display: none;
}

.language-selector {
  cursor: pointer;
  border-radius: 10px;
  font-size: 0.8rem;
  padding: 0.3rem;
  color: black;
}
.language-selector option {
  font-size: 0.9rem;
  color: black;
}

.switch-mobile-language {
  display: none;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border: 0.13rem solid rgba(255, 255, 255, 0.5);
  padding: 0.25rem;
  cursor: pointer;
  transition: 0.5s;
  margin: 0 0 0 0.5rem;
}
.switch-mobile-language:hover {
  background: rgba(255, 255, 255, 0.5);
}
.switch-mobile-language.es .en-svg {
  display: none;
}
.switch-mobile-language.en .es-svg {
  display: none;
}
.switch-mobile-language svg {
  opacity: 0.8;
  transform: scale(60%);
}

body.dark .language-selector {
  background-color: rgba(39, 55, 77, 0.8);
  color: #dde6ed;
}
body.dark .language-selector option {
  color: #dde6ed;
  background: #27374d;
}

.work-cards-container {
  width: 64rem;
  height: 36rem;
  position: relative;
}
.work-cards-container:hover > .layer {
  opacity: 1;
  visibility: visible;
}
.work-cards-container:hover .img-layer img {
  filter: blur(2px);
}
.work-cards-container .img-layer {
  height: 100%;
  width: 100%;
}
.work-cards-container .img-layer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.work-cards-container .layer {
  font-family: "Comfortaa", cursive;
  width: 100%;
  height: 100%;
  visibility: hidden;
  position: absolute;
  top: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
  transition: 0.3s;
}

.tech-li {
  font-family: "Comfortaa", cursive;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s;
}
.tech-li:hover {
  transform: scale(1.05);
}
.tech-li:hover .tech-name {
  transition: color 0.2s, opacity 0.2s;
  color: black;
  opacity: 1;
}
.tech-li .tech-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tech-li .tech-name {
  transition: color 0.2s, opacity 0.2s;
  color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}

body.dark .tech-li:hover .tech-name {
  color: #dde6ed;
}
body.dark .tech-li .tech-name {
  color: rgba(221, 230, 237, 0.5);
}

.header {
  background: rgba(69, 98, 104, 0.8);
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 4rem;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
}
.header__h1 {
  cursor: default;
  position: fixed;
  padding-left: 1rem;
  transition: color 0.3s;
}
.header nav {
  right: 1rem;
  position: absolute;
}
.header nav li {
  padding: 0rem 0.7rem;
  display: inline-block;
}
.header nav li .header__link {
  font-size: 1.02rem;
  text-decoration: none;
  padding: 0 0.6rem 0 0.6rem;
  transition: color 0.15s;
}
.header nav li .header__link:hover {
  color: #abbac2;
}

.intro {
  font-family: "Comfortaa", cursive;
  width: 100%;
  height: 100%;
  padding: 4rem 0 1rem 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../images/backgrounds/10.jpg");
  background-size: cover;
  background-attachment: fixed;
}
.intro .content {
  color: #dde6ed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.intro .content header h2 {
  margin-top: 1rem;
  backdrop-filter: blur(2px);
  margin-bottom: 0;
  text-shadow: 0.1rem 0.1rem 0.2rem #322464;
  text-align: center;
  font-size: 3.5rem;
}
.intro .content p {
  backdrop-filter: blur(2px);
  text-shadow: 0.1rem 0.1rem 0.2rem #322464;
  font-weight: bolder;
  font-size: 1.5rem;
  margin: 1rem;
  text-align: center;
}
.intro .content .social-media-container {
  display: flex;
  align-items: center;
}
.intro .content .social-media-container .intro-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 0.6rem;
  transition: visibility 0.2s;
}
.intro .content .social-media-container .intro-icon svg {
  height: 3rem;
  width: 3rem;
  cursor: pointer;
}
.intro .content .social-media-container .intro-icon span {
  visibility: hidden;
  opacity: 0;
  font-size: 0.7rem;
  transition: opacity 0.3s;
}
.intro .content .social-media-container .intro-icon:hover {
  transform: scale(1.05);
}
.intro .content .social-media-container .intro-icon:hover span {
  visibility: visible;
  opacity: 1;
}
.intro .content footer {
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}
.intro .content footer .down-section-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  border: 0.13rem solid rgba(255, 255, 255, 0.5);
}
.intro .content footer .down-section-link .icon {
  color: rgba(255, 255, 255, 0.3);
  height: 5rem;
  width: 5rem;
  transition: 0.3s;
}
.intro .content footer .down-section-link .icon:hover {
  color: rgba(255, 255, 255, 0.5);
}

.works {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.works .content .work-tittle {
  text-align: center;
}
.works .content .work-tittle h2 {
  font-size: 1.7rem;
}
.works .content .work-tittle p {
  font-size: 1.4rem;
}
.works .content .section-gallery {
  width: 100%;
}

.about-me {
  height: 100vh;
  display: flex;
  align-items: center;
}
.about-me__container {
  margin-left: -30%;
  padding: 20rem 65% 20rem 1rem;
  width: 100%;
  transition: 0.3s;
  height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about-me__container.active {
  margin-left: 0;
}
.about-me__container:hover {
  padding: 25% 32%;
  width: 100%;
}
.about-me__container:hover .about-me__title {
  margin: 0 0 1rem 0;
  font-size: 2rem;
  text-align: center;
  transition: font-size 0.3s, margin 0.3s;
}
.about-me__container:hover .about-me__description {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  transition: font-size 0.1s, line-height 0.3s;
}
.about-me__container .about-me__title {
  transition: font-size 0.2s;
  font-size: 1.2rem;
}
.about-me__container .about-me__description {
  font-size: 1.2rem;
  line-height: 1.7rem;
  transition: line-height 0.3s;
}

.skills {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: hidden;
}
.skills .tech-container {
  width: 72%;
  display: grid;
  grid-template-columns: repeat(3, 20%);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}
.skills .tech-container ul {
  list-style: none;
}
.skills .skills-description-aside {
  position: absolute;
  right: 0;
  margin-right: -30%;
  width: 28%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: margin 0.3s, width 0.3s, transform 0.3s;
  overflow: hidden;
}
.skills .skills-description-aside:hover {
  transform: scale(1.1);
  transition: transform 0.3s;
}
.skills .skills-description-aside:hover .skills-tittle-header {
  transition: font-size 0.15s;
  font-size: 1.8rem;
}
.skills .skills-description-aside:hover .skills-description {
  transition: font-size 0.15s;
  font-size: 1.3rem;
}
.skills .skills-description-aside.active {
  margin-right: 2rem;
}
.skills .skills-description-aside .skills-tittle-header {
  transition: font-size 0.15s;
  font-size: 1.5rem;
}
.skills .skills-description-aside .skills-description {
  transition: font-size 0.15s;
  font-size: 1.2rem;
  line-height: 1.7rem;
}

.footer {
  background: #456268;
  font-family: "Comfortaa", cursive;
  width: 100%;
  position: absolute;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer .icons-ul {
  font-size: 1.2rem;
  list-style: none;
  padding: 5rem 0 0 0;
}
.footer .icons-ul li {
  padding: 0 0 0.5rem 0;
}
.footer .icons-ul li a {
  text-decoration: none;
  transition: color 0.2s;
}
.footer .icons-ul li a:hover {
  color: #dde6ed;
  transition: color 0.15s;
}
.footer .icons-ul li a .icon {
  padding-right: 1rem;
}
.footer .icons-ul li a .label {
  padding: 0.2rem;
}
.footer .sign {
  padding: 2rem 0 1rem 0;
  margin: 0;
  list-style: none;
}
.footer .sign li {
  font-size: 1.5rem;
}

@media (max-width: 1400px) {
  body .header {
    height: 3.5rem;
  }
  body .header nav li .header__link {
    font-size: 0.9rem;
  }
  body .intro .content .profile-picture .image-container {
    margin-top: 2rem;
  }
  body .works .content .section-gallery .work-container .layer .text-container h3 {
    font-size: 1.2rem;
  }
  body .works .content .section-gallery .work-container .layer .text-container p {
    font-size: 0.8rem;
  }
  body .about-me__container {
    margin-left: -42%;
    width: 100%;
  }
  body .about-me__container:hover {
    padding: 25% 32%;
  }
  body .about-me__container:hover .about-me__title {
    font-size: 1.7rem;
  }
  body .about-me__container:hover .about-me__description {
    width: 35rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  body .about-me__container .about-me__title {
    width: 35rem;
    font-size: 1.4rem;
  }
  body .about-me__container .about-me__description {
    width: 35rem;
    font-size: 1.1rem;
    line-height: 1.7rem;
  }
  body .skills .tech-container {
    width: 68%;
    margin-left: -1rem;
  }
  body .skills .skills-description-aside {
    width: 29%;
    margin-left: -1rem;
  }
  body .skills .skills-description-aside:hover .skills-tittle-header {
    font-size: 1.7rem;
  }
  body .skills .skills-description-aside:hover .skills-description {
    font-size: 1.2rem;
  }
  body .skills .skills-description-aside .skills-tittle-header {
    font-size: 1.4rem;
  }
  body .skills .skills-description-aside .skills-description {
    font-size: 1.1rem;
  }
}
@media (max-height: 850px) {
  html {
    font-size: 15px;
  }
  body .skills .tech-container {
    grid-template-columns: repeat(3, 22%);
  }
}
@media (max-height: 790px) {
  html {
    font-size: 14px;
  }
  body .skills .tech-container {
    grid-template-columns: repeat(3, 27%);
  }
}
@media (max-height: 620px) {
  html {
    font-size: 13px;
  }
  body .intro {
    height: 100%;
  }
  body .intro .content .profile-picture .image-container {
    margin: 4rem 0 0 0;
  }
  body .intro .content footer li .switch-mode-mobile {
    width: 6.7rem;
    height: 6.7rem;
  }
  body .skills .tech-container {
    grid-template-columns: repeat(3, 27%);
  }
  body .skills .tech-container ul .tech-li {
    padding: 1rem;
  }
}
@media (max-width: 1030px) {
  .about-me {
    height: 80vh;
  }
  .about-me__container.active {
    opacity: 1;
  }
  .about-me__container:hover {
    padding: 0% 0%;
    margin-left: -7%;
  }
}
@media (max-width: 992px) {
  body .header {
    height: 4rem;
  }
  body .header__h1 {
    font-size: 1.2rem;
  }
  body .header nav ul .switch-mode {
    display: none;
  }
  body .header nav ul .language-selector {
    display: none;
  }
  body .header nav ul li .header__link {
    font-size: 0.9rem;
  }
  body .intro .content footer .switch-mobile-language {
    display: block;
  }
  body .intro .content footer .switch-mode-mobile {
    display: flex;
  }
  body .intro .content footer .down-section-link {
    display: none;
  }
  body .about-me {
    height: 80vh;
    padding: 0 3rem;
  }
  body .about-me__container {
    margin: 0;
    opacity: 0;
    padding: 0;
    width: 100%;
  }
  body .about-me__container.active {
    opacity: 1;
  }
  body .about-me__container:hover {
    margin-left: 0;
    width: 100%;
    padding: 0;
  }
  body .about-me__container:hover .about-me__title {
    width: 100%;
    font-size: 2rem;
  }
  body .about-me__container:hover .about-me__description {
    width: 100%;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
  body .about-me__container .about-me__title {
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 2rem;
  }
  body .about-me__container .about-me__description {
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
  body .skills {
    height: 80vh;
  }
  body .skills .tech-container {
    width: 65%;
    grid-template-columns: repeat(3, 32%);
  }
  body .skills .tech-container ul .tech-li .tech-name {
    text-align: center;
  }
  body .skills .skills-description-aside {
    width: 35%;
    margin-right: -36%;
  }
  body .skills .skills-description-aside.active {
    margin: 0;
    padding: 0 0.5rem 0 0;
  }
  body .skills .skills-description-aside .skills-tittle-header {
    font-size: 1.45rem;
  }
  body .skills .skills-description-aside .skills-description {
    font-size: 1.05rem;
  }
  body .skills .skills-description-aside:hover {
    width: 35%;
    transform: scale(1.05);
  }
  body .skills .skills-description-aside:hover .skills-tittle-header {
    font-size: 1.45rem;
  }
  body .skills .skills-description-aside:hover .skills-description {
    font-size: 1.05rem;
  }
  body .footer .icons-ul {
    padding: 4rem 0 0 0;
    font-size: 1.6rem;
  }
  body .footer .icons-ul li {
    padding: 0 0 1rem 0;
  }
  body .footer .menu {
    font-size: 1.9rem;
  }
}
@media (max-width: 750px) {
  body .header nav ul {
    display: none;
  }
}
@media (max-width: 576px) {
  html {
    font-size: 16px;
  }
  body .header {
    height: 2rem;
    height: 3rem;
    display: flex;
    justify-content: center;
  }
  body .intro .content .profile-picture .image-container img {
    height: 21rem;
    width: 21rem;
  }
  body .intro .content header h2 {
    margin-top: 1.5rem;
    font-size: 2.5rem;
  }
  body .intro .content p {
    margin: 1rem;
    font-size: 1.2rem;
  }
  body .intro .content footer {
    padding-top: 1rem;
  }
  body .intro .content footer li .switch-mode-mobile {
    width: 5.6rem;
    height: 5.6rem;
  }
  body .intro .content .switch-mobile-language {
    border-radius: 50%;
    padding: 0.2rem;
  }
  body .works .content .work-tittle h2 {
    font-size: 1.5rem;
  }
  body .works .content .work-tittle p {
    font-size: 1.2rem;
  }
  body .works .content .section-gallery .work-cards-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body .works .content .section-gallery .work-cards-container .img-layer {
    margin: 0;
  }
  body .works .content .section-gallery .work-cards-container .layer {
    margin: 0;
  }
  body .works .content .section-gallery .work-cards-container .layer .text-container {
    padding: 0.2rem 1rem;
  }
  body .works .content .section-gallery .work-cards-container .layer .text-container h3 {
    padding: 0.9rem 0 0 0;
    margin: 0;
    font-size: 1.2rem;
  }
  body .works .content .section-gallery .work-cards-container .layer .text-container p {
    padding: 0.2rem 0;
    font-size: 0.8rem;
  }
  body .about-me {
    width: 100%;
    padding: 0;
  }
  body .about-me__container:hover .about-me__title {
    font-size: 1.5rem;
  }
  body .about-me__container:hover .about-me__description {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  body .about-me__container .about-me__title {
    font-size: 1.5rem;
  }
  body .about-me__container .about-me__description {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  body .skills {
    overflow-y: hidden;
    height: 100%;
    padding: 3rem 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  body .skills .tech-container {
    margin: 0 0 0 0;
    width: 100%;
    height: 100%;
    display: flex;
  }
  body .skills .tech-container ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  body .skills .tech-container ul .tech-li .tech-img-container {
    width: 4rem;
  }
  body .skills .tech-container ul .tech-li .tech-name {
    font-size: 0.8rem;
    opacity: 1;
  }
  body .skills .skills-description-aside {
    order: -1;
    position: static;
    margin: 0;
    height: 100%;
    width: 100%;
  }
  body .skills .skills-description-aside:hover {
    transform: scale(1);
    width: 100%;
  }
  body .skills .skills-description-aside:hover .skills-tittle-header {
    transition: font-size 0.5s;
    font-size: 1.5rem;
  }
  body .skills .skills-description-aside:hover .skills-description {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  body .skills .skills-description-aside .skills-tittle-header {
    text-align: center;
    transition: font-size 0.5s;
    font-size: 1.5rem;
  }
  body .skills .skills-description-aside .skills-description {
    padding: 0rem 1rem;
    text-align: center;
    transition: font-size 0.5s;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  body .footer .icons-ul {
    margin-left: -2rem;
  }
  body .footer .menu {
    margin-left: -2rem;
  }
}
@media (max-width: 321px) {
  html {
    font-size: 15px;
  }
  body .header {
    height: 2rem;
  }
  body .intro {
    padding: 0 0 1rem 0;
  }
  body .intro .content .profile-picture .image-container img {
    height: 19rem;
    width: 19rem;
  }
  body .intro .content header h2 {
    margin-top: 0.3rem;
    font-size: 1.8rem;
  }
  body .intro .content p {
    padding: 1rem;
    margin: 0.3rem;
    font-size: 1.1rem;
  }
  body .intro .content footer {
    padding-top: 0.5rem;
  }
  body .intro .content footer li .switch-mode-mobile {
    width: 5.5rem;
    height: 5.5rem;
  }
  body .intro .content .switch-mobile-language {
    border-radius: 50%;
    padding: 0rem;
  }
  body .intro .content .switch-mobile-language svg {
    transform: scale(50%);
  }
  body .works .content .section-gallery .work-cards-container .layer .text-container h3 {
    font-size: 1.1rem;
  }
  body .works .content .section-gallery .work-cards-container .layer .text-container p {
    font-size: 0.7rem;
  }
  body .works .content .section-gallery .work-cards-container .layer .techs__ul .tech-li {
    margin: 0 0.2rem 0 0;
  }
  body .works .content .section-gallery .work-cards-container .layer .techs__ul .tech-li .tech-img-container {
    padding: 0.2rem 0.1rem;
    height: 1.8rem;
    width: 1.8rem;
  }
  body .about-me__container:hover .about-me__title {
    font-size: 1.3rem;
  }
  body .about-me__container:hover .about-me__description {
    font-size: 1.05rem;
  }
  body .about-me__container .about-me__title {
    font-size: 1.3rem;
  }
  body .about-me__container .about-me__description {
    font-size: 1.05rem;
  }
  body .skills .tech-container ul .tech-li {
    padding: 1rem;
  }
  body .skills .tech-container ul .tech-li .tech-img-container {
    height: 3rem;
    width: 3rem;
  }
  body .skills .skills-description-aside:hover .skills-tittle-header {
    font-size: 1.3rem;
  }
  body .skills .skills-description-aside:hover .skills-description {
    font-size: 1.05rem;
  }
  body .skills .skills-description-aside .skills-tittle-header {
    font-size: 1.3rem;
  }
  body .skills .skills-description-aside .skills-description {
    font-size: 1.05rem;
  }
}

