@media (max-width: 1030px) {
  //About me
  .about-me {
    height: 80vh;

    &__container {
      &.active {
        opacity: 1;
      }

      &:hover {
        padding: 0% 0%;
        margin-left: -7%;
      }
    }
  }
}

@media (max-width: 992px) {
  body {
    //Header
    .header {
      height: 4rem;

      &__h1 {
        font-size: 1.2rem;
      }

      nav ul {
        .switch-mode {
          display: none;
        }

        .language-selector {
          display: none;
        }

        li {
          .header__link {
            font-size: 0.9rem;
          }
        }
      }
    }

    //intro
    .intro {
      //Link arrow
      .content {
        footer {
          .switch-mobile-language {
            display: block;
          }

          .switch-mode-mobile {
            display: flex;
          }

          .down-section-link {
            display: none;
          }
        }
      }
    }

    //About me
    .about-me {
      height: 80vh;
      padding: 0 3rem;

      &__container {
        margin: 0;
        opacity: 0;
        padding: 0;
        width: 100%;

        &.active {
          opacity: 1;
        }

        &:hover {
          margin-left: 0;
          width: 100%;
          padding: 0;

          .about-me__title {
            width: 100%;
            font-size: 2rem;
          }

          .about-me__description {
            width: 100%;
            font-size: 1.6rem;
            line-height: 2.2rem;
          }
        }

        .about-me__title {
          width: 100%;
          margin: 0;
          text-align: center;
          font-size: 2rem;
        }

        .about-me__description {
          width: 100%;
          text-align: center;
          margin: 0;
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }
    }

    //Skills
    .skills {
      height: 80vh;

      .tech-container {
        width: 65%;
        grid-template-columns: repeat(3, 32%);

        ul {
          .tech-li {
            .tech-name {
              text-align: center;
            }
          }
        }
      }

      .skills-description-aside {
        width: 35%;
        margin-right: -36%;

        &.active {
          margin: 0;
          padding: 0 0.5rem 0 0;
        }

        .skills-tittle-header {
          font-size: 1.45rem;
        }

        .skills-description {
          font-size: 1.05rem;
        }

        &:hover {
          width: 35%;
          transform: scale(1.05);

          .skills-tittle-header {
            font-size: 1.45rem;
          }

          .skills-description {
            font-size: 1.05rem;
          }
        }
      }
    }

    //Footer
    .footer {
      .icons-ul {
        padding: 4rem 0 0 0;
        font-size: 1.6rem;

        li {
          padding: 0 0 1rem 0;
        }
      }

      .menu {
        font-size: 1.9rem;
      }
    }
  }
}

@media (max-width: 750px) {
  body {
    //Header
    .header {
      nav ul {
        display: none;
      }
    }
  }
}
