.about-me {
  height: 100vh;
  display: flex;
  align-items: center;

  &__container {
    margin-left: -30%;
    padding: 20rem 65% 20rem 1rem;
    width: 100%;
    transition: 0.3s;
    height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.active {
      margin-left: 0;
    }

    &:hover {
      padding: 25% 32%;
      width: 100%;

      .about-me__title {
        margin: 0 0 1rem 0;
        font-size: 2rem;
        text-align: center;
        transition: font-size 0.3s, margin 0.3s;
      }

      .about-me__description {
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: center;
        transition: font-size 0.1s, line-height 0.3s;
      }
    }

    .about-me__title {
      transition: font-size 0.2s;
      font-size: 1.2rem;
    }

    .about-me__description {
      font-size: 1.2rem;
      line-height: 1.7rem;
      transition: line-height 0.3s;
    }
  }
}
