.switch-mode {
  background-color: #343d5b;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 0.11rem;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    border-radius: 20px;
    width: 2.1rem;
    height: 2.1rem;
    background: white;
    display: block;
    position: absolute;
    right: unset;
    left: 0;
  }

  &.active {
    background: rgb(161, 161, 161);
    color: black;

    span {
      color: black;
    }

    &::after {
      right: 0;
      left: unset;
    }
  }

  span {
    width: 1.85rem;
    height: 1.85rem;
    line-height: 1.85rem;
    display: block;
    color: white;
    margin: 0px 0px 0px 5px;
  }
}

.switch-mode-mobile {
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  background: rgba($color: #ffffff, $alpha: 0.3);
  border: 0.13rem solid rgba($color: #ffffff, $alpha: 0.5);
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba($color: #ffffff, $alpha: 0.5);
    transition: 0.5s;
  }

  &.active {
    color: #ffffff;
  }

  span {
    font-size: 3rem;
  }
}
