.work-cards-container {
  width: 64rem;
  height: 36rem;
  position: relative;

  &:hover > .layer {
    opacity: 1;
    visibility: visible;
  }

  &:hover .img-layer img {
    filter: blur(2px);
  }

  .img-layer {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .layer {
    font-family: $comfortaa-cursive;
    width: 100%;
    height: 100%;
    visibility: hidden;
    position: absolute;
    top: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    transition: 0.3s;
  }
}
