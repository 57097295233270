.tech-li {
  font-family: $comfortaa-cursive;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s;

  &:hover {
    transform: scale(1.05);

    .tech-name {
      transition: color 0.2s, opacity 0.2s;
      color: black;
      opacity: 1;
    }
  }

  .tech-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tech-name {
    transition: color 0.2s, opacity 0.2s;
    color: rgba(0, 0, 0, 0.7);
    opacity: 0;
  }
}

body {
  &.dark {
    .tech-li {
      &:hover {
        .tech-name {
          color: map-get($dark, mystic);
        }
      }

      .tech-name {
        color: transparentize(map-get($dark, mystic), 0.5);
      }
    }
  }
}
